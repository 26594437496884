export const groupBy = <T>(iterable: T[], key: (i: T) => string) => {
  const map = new Map<string, T[]>();
  iterable.forEach(item => {
    const k = key(item);
    const collection = map.get(k);
    if (!collection) {
      map.set(k, [item])
    } else {
      collection.push(item)
    }
  });
  return map;
}

export const formatDate = (date?: number | string | Date, opts?: { separator: string }) => {
  if (!date) return '';

  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export const formatDateTime = (date?: number | string | Date, opts?: { separator: string }) => {
  if (!date) return '';

  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minutes = '' + d.getMinutes()
    ;

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  if(hour.length < 2) 
    hour = '0' + hour;  
  if(minutes.length < 2) 
    minutes = '0' + minutes;

  return `${year}-${month}-${day} ${hour}:${minutes}`;
}

export const formatFloat = (value?: string, precision: number = 0, defaultValue: string = '') => {
  return value && parseFloat(value).toFixed(precision) || defaultValue;
}

export const parseBool = (value?: string | number | boolean) => {
  switch (value) {
    case true:
    case "true": return true;
    case false:
    case "false": return false;
    default: return null;
  }
}

export const formatYN = (value?: boolean) => {
  switch (parseBool(value)) {
    case true: return 'YES';
    case false: return 'NO';
    default: return '';
  }
}

export const safeParseInt = (value: string, defaultValue = 0) => {
  const i = parseInt(value);
  if (isNaN(i)) return defaultValue;
  return i;
}