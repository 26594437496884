import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { service } from '~/src/service';
import { Page } from '~/src/ux';
import { formatDate } from '~/src/monad';
import { Button, Form, Icon, Input, Label, Menu, Segment, Tab } from 'semantic-ui-react';
import { PatientReportDaily } from './patient_report_daily';
import { PatientForms } from './patient_forms';
import { PatientAdmin } from './patient_admin';
import { PatientNote } from './patient_note';

interface Patient {
  userId: string;
  email?: string;
  createdAt?: Date;
  deletedProfileAt?: Date;
}

interface Admission {
  lastContactedAt?: Date;
  notes?: string;
}

export const PatientViewer = () => {
  const { id } = useParams() as any;
  const [reportDailyData, setReportDailyData] = useState<any[]>([]);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [formData, setQuest] = useState<any[]>([]);

  const [busy, setBusy] = useState(false);
  const [dirty, setDirty] = useState(0);

  const history = useHistory();

  useEffect(() => {

    const load = async () => {
      setBusy(true);

      try {
        const { patient } = await service.api.patient.find(id);
        setPatient(patient);

      } catch (e) {
        setPatient(null);
      }

      try {
        const reportDaily = await service.api.getPatientReportDaily(id);
        setReportDailyData(reportDaily);
      } catch (e) {
        setReportDailyData([]);
      }

      setBusy(false);
    }

    load().then();
  }, [dirty, id]);

  const notify = async () => {
    setBusy(true);
    try {
      await service.api.postPatientNotify(id);
    } catch (e) {
    }
    setBusy(false);
  }

  return <Page>
    <Menu secondary>
      <Menu.Item fitted>
        <Button as='div' labelPosition='right'>
          <Button basic onClick={() => history.push('/patients')}>
            <Icon name='folder open'></Icon>
            Patients
          </Button>
          <Label as='a' basic pointing='left'>
            {patient?.email}
          </Label>
        </Button>
      </Menu.Item>

      <Menu.Item position='right' icon='bullhorn' onClick={() => notify()}>

      </Menu.Item>
    </Menu>

    <Segment>
      <Form>
        <Form.Group widths='equal'>
          <Form.Input fluid label='email' placeholder='email' value={patient?.email ?? ''} />
          <Form.Input fluid label='joined' placeholder='joined' value={!!patient ? formatDate(patient?.createdAt) : ''} />
          <Form.Input fluid label='deleted' placeholder='deleted' value={!!patient ? formatDate(patient?.deletedProfileAt) : ''} />
        </Form.Group>
      </Form>
    </Segment>


    <Tab menu={{ pointing: true }} panes={[
      { menuItem: 'Daily', render: () => <PatientReportDaily data={reportDailyData} /> },
      { menuItem: 'Notes', render: () => <PatientNote patientId={id} /> },
      { menuItem: 'Forms', render: () => <PatientForms patientId={id} /> },
      { menuItem: 'Admin', render: () => <PatientAdmin patientId={id} /> },
    ]} />
  </Page>
}

