import * as firebase from 'firebase/app';
import 'firebase/auth';
import { formatDate } from './monad';
import { NudgeTemplate } from './types/nudge_template';

const firebaseConfig = {
  apiKey: "AIzaSyATsmdmH7vdL-oAPwHUfkPDl7G7ZyvKhs0",
  authDomain: "craetus.firebaseapp.com",
  databaseURL: "https://craetus.firebaseio.com",
  projectId: "craetus",
  storageBucket: "craetus.appspot.com",
  messagingSenderId: "990506783487",
  appId: "1:990506783487:web:2339950cb9c0062be7b2ca"
};

const apiConfig = {
  baseUrl: "https://europe-west2-craetus.cloudfunctions.net/api",
  //baseUrl: "http://localhost:5001/craetus/europe-west2/api"
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

const auth = firebaseApp.auth();

const getToken = async () => auth.currentUser?.getIdToken();
const options = async () => {
  const token = await getToken();
  return { headers: { 'Authorization': `Bearer ${token}` } };
};

const apiGet = (url: string) => options().then(opt => fetch(`${apiConfig.baseUrl}${url}`, opt)).then(response => {
  if (response.ok)
    return response.json();
  throw response.json()
});

const apiPost = (url: string, body?: any) => options().then(opt => fetch(`${apiConfig.baseUrl}${url}`, {
  headers: {
    ...opt.headers,
    'Content-Type': 'application/json'
  }, method: 'POST', body: JSON.stringify(body)
})).then(response => {
  console.log(body);
  if (response.ok)
    return response.json();
  console.log(response);
  throw response.json()
});

const apiPatch = (url: string, body?: any) => options().then(opt => fetch(`${apiConfig.baseUrl}${url}`, {
  headers: {
    ...opt.headers,
    'Content-Type': 'application/json'
  }, method: 'PATCH', body: JSON.stringify(body)
})).then(response => {
  if (response.ok)
    return response.json();
  throw response.json()
});

const apiDelete = (url: string, body?: any) => options().then(opt => fetch(`${apiConfig.baseUrl}${url}`, {
  headers: {
    ...opt.headers,
    'Content-Type': 'application/json'
  }, method: 'DELETE', body: JSON.stringify(body)
})).then(response => {
  if (response.ok)
    return response.json();
  throw response.json()
});

const pivotDaily = (items: { date: string, code: string, value: number | string | boolean }[]): any[] => {
  const raw: any = {};
  items.forEach(x => {
    const date = formatDate(x.date);
    let day = raw[date];
    if (!day) day = raw[date] = { date };
    day[x.code] = x.value;
  });
  const daily = Object.values(raw);
  return daily;
};


export const service = {
  auth,
  signInWithEmailAndPassword: (email: string, password: string) => auth.signInWithEmailAndPassword(email, password),
  signOut: () => auth.signOut(),
  api: {
    getPatientReportDaily: (userId: string): Promise<any[]> => apiGet(`/patient/${userId}/report/daily`),
    fetchPatientDaily: (id: string): Promise<any[]> => apiGet(`/fetchPatientDaily/${id}`).then(({ patientDaily }) => pivotDaily(patientDaily)),
    fetchPatientQuests: (id: string): Promise<any[]> => apiGet(`/fetch/patient/${id}/quests`).then(({ quests }) => quests),
    postQuest: (body: { id?: string, userId: string, form: string, activeSince?: Date, activeUntil?: Date, disabled?: boolean, bucket?: string }) => apiPost(`/post/quest`, body),
    postPatientNotify: (id: string) => apiPost(`/patient/${id}/notify`),
    patient: {
      query: () => apiGet('/fetchPatients'),
      find: (patientId: string) => apiGet(`/fetchPatient/${patientId}`),
      forms: {
        query: (patientId: string) => apiGet(`/patients/${patientId}/quest/state`),
      },
      admin: {
        query: (patientId: string) => apiGet(`/user/${patientId}/link_admission`),
        add: (patientId: string, adminId: string) => apiPost('/user/link_admission', { patientId, adminId }),
        delete: (patientId: string, adminId: string) => apiDelete('/user/link_admission', { patientId, adminId }),
      },
      admission: {
        query: (patientId: string) => apiGet(`/patient/${patientId}/admission`),
        update: (patientId: string, admission: any) => apiPost(`/patient/${patientId}/admission`, admission),
      },
      note: {
        query: (patientId: string) => apiGet(`/patient/${patientId}/notes`),
        update: (patientId: string, note: {id:string, at?: Date, note?: string}) => apiPatch(`/patient/${patientId}/note/${note.id}`, note),
        insert: (patientId: string, note: {at?: Date, note?: string}) => apiPost(`/patient/${patientId}/note`, note),
      }
    },
    user: {
      find: (userId: string) => apiGet(`/user/${userId}`),
      update: (userId: string, { hasRoleAdmin, hasRoleRoot }: { hasRoleAdmin?: boolean, hasRoleRoot?: boolean }) => apiPost(`/user/${userId}/update`, {
        hasRoleAdmin,
        hasRoleRoot,
      }),
    },
    form: {
      query: () => apiGet('/form/query'),
    },
    nudgeTemplate: {
      query: () => apiPost('/nudge-template/query').then(({ entries }: { entries: NudgeTemplate[] }) => entries),
      find: (id: string) => apiPost(`/nudge-template/${id}/find`).then(({ entry }: { entry: NudgeTemplate }) => entry),
      save: (entry: NudgeTemplate) => apiPost('/nudge-template/save', entry),
      delete: (id: string) => apiPost(`/nudge-template/${id}/delete`),
    }
  },
}

