import React, { useEffect, useState } from "react"
import { Divider, Dropdown, DropdownItem, Menu, Segment, Table } from "semantic-ui-react"
import { forms } from "~/src/data/forms"
import { formatDate, formatFloat } from "~/src/monad"
import { service } from "~/src/service"
import { Body } from "~/src/ux/page"

export const PatientForms = ({ patientId }: { patientId: string }) => {

  const [data, setData] = useState([]);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<any>();
  const [snap, setSnap] = useState(0);

  const assignForm = async (form: string, bucket: string) => {
    setBusy(true);
    setError(null);
    try {
      const quest = { userId: patientId, form, bucket };
      await service.api.postQuest(quest);
    } catch (e) {
      setError(e);
    }
    setBusy(false);
    reload();
  };

  useEffect(() => {
    async function load() {
      if (busy) return;

      setBusy(true);
      setError(null);
      try {
        const data = await service.api.patient.forms.query(patientId);
        setData(data);
      } catch (e) {
        setError(e);
      }
      setBusy(false);
    }

    load();
  }, [patientId, snap]);

  const reload = () => setSnap(snap + 1);


  return <Body busy={busy} error={error}>
    <Menu>
      <Menu.Item link>
        <Dropdown text='Assign Form'>
          <Dropdown.Menu>
            <Dropdown.Header content='PRE' />
            {forms.map((form) => <Dropdown.Item key={form.code} content={form.code} onClick={() => assignForm(form.code, 'PRE')} />)}
            <Divider />
            <Dropdown.Header content='POST' />
            {forms.map((form) => <Dropdown.Item key={form.code} content={form.code} onClick={() => assignForm(form.code, 'POST')} />)}
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
      <Menu.Item link icon='sync' onClick={() => reload()} />
    </Menu>

    <Table>
      <Table.Header>
        <Table.HeaderCell />
        <Table.HeaderCell content='Bucket' />
        <Table.HeaderCell content='Form' />
        <Table.HeaderCell content='Completed' />
        <Table.HeaderCell content='Score' />
      </Table.Header>
      <Table.Body>
        {data.map((row: any) => <Table.Row>
          <Table.Cell collapsing icon={row.completedAt ? 'check square' : 'square outline'} />
          <Table.Cell content={row.bucket} />
          <Table.Cell content={row.form} />
          <Table.Cell content={formatDate(row.completedAt)} />
          <Table.Cell>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {Object.entries(score(row)).map(([key, value]) => <div
                style={{ display: 'flex', flexDirection: 'column', paddingRight: '16px' }}>
                <div style={{ borderBottom: '1px solid #ccc' }}><b>{key}</b></div>
                <div>{value}</div>
              </div>)}
            </div>
          </Table.Cell>
        </Table.Row>)}
      </Table.Body>
    </Table>
  </Body >
}

const score = (quest: MxQuest) => {
  const s: { [key: string]: string } = {};
  const state = quest.state ?? {};
  switch (quest.form) {
    case "SPADI": {
      s["PAIN"] =
        formatFloat((((parseInt(state["P1"] ?? "0")
          + parseInt(state["P2"] ?? "0")
          + parseInt(state["P3"] ?? "0")
          + parseInt(state["P4"] ?? "0")
          + parseInt(state["P5"] ?? "0")
        ) / (((!!state["P1"] ? 1 : 0)
          + (!!state["P2"] ? 1 : 0)
          + (!!state["P3"] ? 1 : 0)
          + (!!state["P4"] ? 1 : 0)
          + (!!state["P5"] ? 1 : 0)) * 10.0)) * 100.0).toString(), 2);
      s["DISABILITY"] =
        formatFloat((((parseInt(state["D6"] ?? "0")
          + parseInt(state["D7"] ?? "0")
          + parseInt(state["D8"] ?? "0")
          + parseInt(state["D9"] ?? "0")
          + parseInt(state["D10"] ?? "0")
          + parseInt(state["D11"] ?? "0")
          + parseInt(state["D12"] ?? "0")
          + parseInt(state["D13"] ?? "0")
        ) / (((!!state["D6"] ? 1 : 0)
          + (!!state["D7"] ? 1 : 0)
          + (!!state["D8"] ? 1 : 0)
          + (!!state["D9"] ? 1 : 0)
          + (!!state["D10"] ? 1 : 0)
          + (!!state["D11"] ? 1 : 0)
          + (!!state["D12"] ? 1 : 0)
          + (!!state["D13"] ? 1 : 0)
        ) * 10.0)) * 100.0).toString(), 2);

    }
      break;
    case 'EQ-5D-3L': {
      s["Q1"] = state["EQ1"] ?? "X";
      s["Q2"] = state["EQ2"] ?? "X";
      s["Q3"] = state["EQ3"] ?? "X";
      s["Q4"] = state["EQ4"] ?? "X";
      s["Q5"] = state["EQ5"] ?? "X";
      s["VAS"] = state["EQ6"] ?? "X";
    }
      break;
    case 'WHODAS20': {
      s["%"] = formatFloat(
        ((parseInt(state["S1"] ?? "0")
          + parseInt(state["S2"] ?? "0")
          + parseInt(state["S3"] ?? "0")
          + parseInt(state["S4"] ?? "0")
          + parseInt(state["S5"] ?? "0")
          + parseInt(state["S6"] ?? "0")
          + parseInt(state["S7"] ?? "0")
          + parseInt(state["S8"] ?? "0")
          + parseInt(state["S9"] ?? "0")
          + parseInt(state["S10"] ?? "0")
          + parseInt(state["S11"] ?? "0")
          + parseInt(state["S12"] ?? "0")
        ) / 48.0 * 100.0).toString(), 2);
      s["H1"] = formatFloat(state["H1"] ?? "0", 0);
      s["H2"] = formatFloat(state["H2"] ?? "0", 0);
      s["H3"] = formatFloat(state["H3"] ?? "0", 0);
    }
      break;
    case "GAD7": {
      s["T"] = "" + (parseInt(state["GAD7_1"] ?? "0")
        + parseInt(state["GAD7_2"] ?? "0")
        + parseInt(state["GAD7_3"] ?? "0")
        + parseInt(state["GAD7_4"] ?? "0")
        + parseInt(state["GAD7_5"] ?? "0")
        + parseInt(state["GAD7_6"] ?? "0")
        + parseInt(state["GAD7_7"] ?? "0"));
    }
      break;
    case "FACIT": {
      s["FACIT"] = formatFloat((((parseInt(state["HI7"] ?? "0")
        + parseInt(state["HI12"] ?? "0")
        + parseInt(state["An1"] ?? "0")
        + parseInt(state["An2"] ?? "0")
        + parseInt(state["An3"] ?? "0")
        + parseInt(state["An4"] ?? "0")
        + parseInt(state["An5"] ?? "0")
        + parseInt(state["An7"] ?? "0")

        + parseInt(state["An8"] ?? "0")
        + parseInt(state["An12"] ?? "0")
        + parseInt(state["An14"] ?? "0")
        + parseInt(state["An15"] ?? "0")
        + parseInt(state["An16"] ?? "0")


      ) / (((!!state["HI7"] ? 1 : 0)
        + (!!state["HI12"] ? 1 : 0)
        + (!!state["An1"] ? 1 : 0)
        + (!!state["An2"] ? 1 : 0)
        + (!!state["An3"] ? 1 : 0)
        + (!!state["An4"] ? 1 : 0)
        + (!!state["An5"] ? 1 : 0)
        + (!!state["An7"] ? 1 : 0)
        + (!!state["An8"] ? 1 : 0)
        + (!!state["An12"] ? 1 : 0)
        + (!!state["An14"] ? 1 : 0)
        + (!!state["An15"] ? 1 : 0)
        + (!!state["An16"] ? 1 : 0)
      ) * 10.0)) * 100.0).toString(), 2)
    } break;
    case "PAR-Q": {
      s["PAR-Q"] = "" + (parseInt(state["P1"] ?? "0")
        + parseInt(state["P2"] ?? "0")
        + parseInt(state["P3"] ?? "0")
        + parseInt(state["P4"] ?? "0")
        + parseInt(state["P5"] ?? "0")
        + parseInt(state["P6"] ?? "0")
        + parseInt(state["P7"] ?? "0")
        );
        s["EXTRA"] = state["PAR-Q-EXTRA"] ?? '';
    } break;
    case "PAS": {
      s["PAS"] = "" + (parseInt(state["PA1"] ?? "0")
        + parseInt(state["PA2"] ?? "0")
        + parseInt(state["PA3"] ?? "0")
        + parseInt(state["PA4"] ?? "0")
        + parseInt(state["PA5"] ?? "0")
      );
    } break
    case "NS": {
      s["NS"] = "" + (parseInt(state["NS1"] ?? "0")
        + parseInt(state["NS2"] ?? "0")
        + parseInt(state["NS3"] ?? "0")
      );

    } break
    case "DL": {
      s["DL"] = "" + (parseInt(state["DL1"] ?? "0")
        + parseInt(state["DL2"] ?? "0")
      );

    }
  }
  return s;
}

type MxQuest = {
  bucket: string;
  form: string;
  activeSince?: Date;
  activeUntil?: Date;
  completedAt?: Date;
  state?: { [key: string]: string };
}