import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import { service } from '~/src/service';
import { Body } from '~/src/ux/page';

export const FormFinder = () => {

  const [busy, setBusy] = useState(false);

  const [items, setItems] = useState([]);
  useEffect(() => {
    async function load() {
      setBusy(true);
      setItems(await service.api.form.query());
      setBusy(false);
    }
    load();
  }, [])

  return <Body busy={busy}>
    <Table celled selectable sortable definition compact>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell width='1' content='Code' />
          <Table.HeaderCell width='4' content='Description' />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((row: any) => {
          return <Table.Row>
            <Table.Cell content={row.code} />
            <Table.Cell content={row.description} />
          </Table.Row>;
        })}
      </Table.Body>
    </Table>
  </Body>
}