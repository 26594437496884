import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Checkbox, Form, Icon, Label, Menu, Segment } from 'semantic-ui-react';
import { safeParseInt } from '~/src/monad';
import { service } from '~/src/service';
import { NudgeTemplate } from '~/src/types/nudge_template';
import { Page, } from '~/src/ux';
import { Body } from '~/src/ux/page';



export const NudgeTemplateEditor = () => {
  const { id } = useParams() as any;
  const history = useHistory();
  const [entry, setEntry] = useState<NudgeTemplate | null>(null);
  const [busy, setBusy] = useState(false);
  const [dirty, setDirty] = useState(0);

  useEffect(() => {
    const load = async () => {
      setBusy(true);
      const entry = await service.api.nudgeTemplate.find(id);
      setEntry(entry);
      setBusy(false);
    }
    load();
  }, [id, dirty]);

  const refresh = () => setDirty(dirty + 1);

  const save = async () => {
    if (!entry) return;
    setBusy(true);
    await service.api.nudgeTemplate.save(entry);
    setBusy(false);
    refresh();
  }

  return <Page>
    <Menu secondary>
      <Menu.Item fitted>
        <Button as='div' labelPosition='right'>
          <Button basic onClick={() => history.push('/settings')}>
            <Icon name='folder open'></Icon>
            Nudge Templates
          </Button>
          <Label as='a' basic pointing='left'>
            Edit
          </Label>
        </Button>
      </Menu.Item>

      <Menu.Item icon='sync' onClick={refresh} />
      <Menu.Item position='right' as='a' icon='save' color='blue' link content='Save' onClick={save} />
    </Menu>

    {entry && <Body busy={busy}>
      <Segment>
        <Form>
          <Form.Group widths='equal'>
            <Form.Input
              label='Title'
              value={entry.title}
              onChange={(e) => setEntry({
                ...entry,
                title: e.target.value,
              })}
            />
            <Form.Input label='Idle Days Trigger' value={entry.idleDaysTrigger}
              onChange={(e) => setEntry({
                ...entry,
                idleDaysTrigger: safeParseInt(e.target.value),
              })} />
            <Form.Input label='Active'>
              <Checkbox
                checked={entry.isEnabled == 1}
                indeterminate={false}
                onChange={(e) => setEntry({
                  ...entry,
                  isEnabled: !!entry.isEnabled ? 0 : 1
                })} />
            </Form.Input>
          </Form.Group>
          <Form.Input
            label='Body'
            value={entry.body}
            onChange={(e) => setEntry({
              ...entry,
              body: e.target.value,
            })}
          />
        </Form>
      </Segment>
    </Body>
    }
  </Page>
}