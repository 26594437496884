import React from 'react';
import { Typography, IconButton, Button } from '@material-ui/core';
import { Page } from '~/src/ux';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import { service } from '~/src/service';

export const Profile = () => {
    return <Page>
        <IconButton>
            <AccountBoxRoundedIcon />
        </IconButton>
        <Typography>Profile</Typography>
        <Button style={{ marginLeft: 'auto' }} onClick={service.signOut}>
            <Typography variant='inherit'>Sign Out</Typography>
        </Button>
    </Page>
}