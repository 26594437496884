import React, { useEffect, useContext, useState, useReducer } from 'react';
import { Button, Menu, Icon, Table, Input, Dropdown, Pagination } from 'semantic-ui-react'
import { service } from '~/src/service';
import { UserContext } from '~/src/providers/user_provider';
import { useHistory } from 'react-router-dom';
import { formatDate } from '~/src/monad';
import { tableController } from '~/src/controllers/table_controller';
import { Page } from '~/src/ux';
import { Body } from '~/src/ux/page';

interface Patient {
  userId: string;
  name?: string;
  email?: string;
  dateOfBirth?: Date;
  createdAt?: Date;
  deletedProfileAt?: Date;
}

const patientPredicate = (item: Patient, term: string[]): boolean => {
  const parts = [item.name?.toLowerCase(), item.email?.toLowerCase()];
  return !term.length || term.every((t) => parts.findIndex((v) => (v?.includes(t) ?? false)) != -1);
}

export const PatientFinder = () => {

  const { user } = useContext(UserContext);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<any>();
  const [snap, setSnap] = useState(0);


  const [state, dispatch] = useReducer(tableController, {
    data: [],
    page: 0,
    pageSize: 25,
  })
  const { sortColumn, sortDirection, view, selected } = state

  useEffect(() => {
    async function load() {
      if (!user || busy) {
        return;
      };

      setBusy(true);
      setError(null);
      try {
        const { patients } = await service.api.patient.query()
        dispatch({ type: 'data', data: patients });
      } catch (e) {
        setError(e);
      }
      setBusy(false);

    }

    load();
  }, [snap]);

  const refresh = () => setSnap(snap + 1);

  const history = useHistory();

  return <Page>
    <Menu secondary>
      <Menu.Item fitted>
        <Button icon labelPosition='left' onClick={refresh}>
          <Icon name='folder open' />
          Patients
        </Button>
      </Menu.Item>
      <Menu.Item fitted>
        <Input placeholder='Search by name, email...' icon='search' onChange={(e) => dispatch({
          type: 'filter',
          term: e.target.value,
          predicate: patientPredicate,
        })} />
      </Menu.Item>
    </Menu>

    <Body busy={busy} error={error}>
      <Table celled selectable sortable definition compact>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell
              sorted={sortColumn === 'createdAt' ? sortDirection : undefined}
              onClick={() => dispatch({ type: 'sort', column: 'createdAt' })}
              content='Joined' />
            <Table.HeaderCell
              sorted={sortColumn === 'name' ? sortDirection : undefined}
              onClick={() => dispatch({ type: 'sort', column: 'name' })}
              content='Name' />
            <Table.HeaderCell
              sorted={sortColumn === 'email' ? sortDirection : undefined}
              onClick={() => dispatch({ type: 'sort', column: 'email' })}
              content='E-mail' />
            <Table.HeaderCell
              sorted={sortColumn === 'dateOfBirth' ? sortDirection : undefined}
              onClick={() => dispatch({ type: 'sort', column: 'dateOfBirth' })}
              content='DoB' />
            <Table.HeaderCell content='Admin' />
            <Table.HeaderCell content='deleted' />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {view?.map((row: Patient) =>
            <Table.Row key={row.userId} positive={selected == row}
              onClick={() => history.push(`/patients/${row.userId}`)}
            >
              <Table.Cell collapsing>
                <Dropdown
                  floating
                  icon='circle outline' />
              </Table.Cell>
              <Table.Cell content={formatDate(row.createdAt)} />
              <Table.Cell content={row.name} />
              <Table.Cell content={row.email} />
              <Table.Cell content={formatDate(row.dateOfBirth)} />
              <Table.Cell></Table.Cell>
              <Table.Cell content={formatDate(row.deletedProfileAt)} />
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Pagination
          activePage={state.page}
          pointing
          secondary
          onPageChange={(_, { activePage }) => dispatch({ type: 'page', page: +(activePage ?? 0) })}
          totalPages={state.pageCount ?? 0}
        />
      </div>
    </Body>

  </Page>
}
