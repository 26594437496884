import _ from 'lodash';
type SortDirection = 'ascending' | 'descending';
type FilterPredicate = (item: any, terms: string[]) => boolean;

type TableState = {
    sortColumn?: string;
    sortDirection?: SortDirection;
    filterPredicate?: FilterPredicate;
    filterTerm?: string[];

    data: any[];

    page: number;
    pageSize: number;
    pageCount?: number;

    view?: any[];
    selected?: any;
}

type TableAction = {
    type: 'filter';
    term: string;
    predicate: (item: any, term: string[]) => boolean;
} | {
    type: 'sort';
    column: string;
} | {
    type: 'data';
    data: any[];
} | {
    type: 'selected',
    selected: any;
} | {
    type: 'page',
    page: number,
} | {
    type: 'page_size',
    pageSize: number,
};

const tableController = (state: TableState, action: TableAction): TableState => {
    const _view = (state: TableState): TableState => {
        var view = state.data;
        if (state.filterPredicate && !!state.filterTerm?.length) {
            view = _.filter(state.data, (e: any) => state.filterPredicate!(e, state.filterTerm!));
        }
        const page = state.page - 1;
        return {
            ...state,
            view: view.slice(page * state.pageSize, page * state.pageSize + state.pageSize),
            pageCount: Math.floor(view.length / state.pageSize),
        };
    }


    switch (action.type) {
        case 'data':
            var data = action.data;
            if (state.sortColumn && state.sortDirection) {
                data = _.sortBy(data, [state.sortColumn])
                if (state.sortDirection == 'descending') {
                    data.reverse();
                }
            }
            return _view({
                ...state,
                data: data,
                page: 1,
            })
        case 'filter': {
            return _view({
                ...state,
                filterPredicate: action.predicate,
                filterTerm: action.term.toLowerCase().split(/(?:;,| -)+/),
                page: 1,
            });
        }
        case 'selected': {
            console.log(action.selected);
            return _view({
                ...state,
                selected: state.selected == action.selected ? undefined : action.selected,
            })
        }
        case 'sort':
            if (state.sortColumn === action.column) {
                return _view({
                    ...state,
                    data: state.data.slice().reverse(),
                    sortDirection:
                        state.sortDirection === 'ascending' ? 'descending' : 'ascending',
                })
            }

            return _view({
                ...state,
                sortColumn: action.column,
                sortDirection: 'ascending',
                data: _.sortBy(state.data, [action.column]),
            });
        case 'page':
            return _view({
                ...state,
                page: action.page,
            });
        case 'page_size':
            return _view({
                ...state,
                pageSize: action.pageSize,
            }); default:
            throw new Error()
    }
}

export {
    tableController,
}