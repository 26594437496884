import React, { useEffect, useState } from 'react';
import { NudgeTemplate } from '~/src/types/nudge_template';
import { service } from '~/src/service';
import { useHistory } from 'react-router-dom';
import { Checkbox, Divider, Form, Menu, Segment, Table } from 'semantic-ui-react';
import { Body } from '~/src/ux/page';

export const NudgeTemplateFinder = () => {
  const history = useHistory();
  const [busy, setBusy] = useState(false);
  const [items, setItems] = useState([] as NudgeTemplate[]);


  useEffect(() => {
    async function load() {
      setBusy(true);
      const entries = await service.api.nudgeTemplate.query();
      setItems(entries.sort((a, b) => a.idleDaysTrigger - b.idleDaysTrigger));
      setBusy(false);
    }
    load();
  }, []);

  return <Body busy={busy}>
    <Table style={{ margin: 0, flex: '1 1 0' }} selectable>
      <Table.Header>
        <Table.HeaderCell />
        <Table.HeaderCell>Title</Table.HeaderCell>
        <Table.HeaderCell>Body</Table.HeaderCell>
        <Table.HeaderCell>Idle Days Trigger</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {items.map((row: NudgeTemplate) => {
          return <Table.Row key={row.id} onClick={() => history.push(`/nudge-template/${row.id}`)}>
            <Table.Cell collapsing icon={row.isEnabled ? 'check' : undefined} />
            <Table.Cell content={row.title} />
            <Table.Cell content={row.body} />
            <Table.Cell content={row.idleDaysTrigger} />
          </Table.Row>
        })}
      </Table.Body>
    </Table>
  </Body>
}