import React, { createContext } from "react";
import { service } from "../service";
import { Login } from '../views/login';

export const UserContext = createContext<{ user: firebase.User | null }>({ user: null });

export class UserProvider extends React.Component<{}, { user: firebase.User | null }> {
    state = {
        user: null
    };

    componentDidMount = () => {
        service.auth.onAuthStateChanged(userAuth => {
            this.setState({ user: userAuth });
        });
    }

    render() {
        return this.state.user
            ? <UserContext.Provider value={{ user: this.state.user }}>
                {this.props.children}
            </UserContext.Provider>
            : <Login />;
    }

}

