import React, { useEffect, useState } from "react"
import { Button, Divider, Dropdown, DropdownItem, Icon, Input, Menu, Segment, Table } from "semantic-ui-react"
import { forms } from "~/src/data/forms"
import { formatDateTime } from "~/src/monad"
import { service } from "~/src/service"
import { Body } from "~/src/ux/page"

interface Note {
    id: string;
    at?: Date;
    note?: string;
}

export const PatientNote = ({ patientId }: { patientId: string }) => {
  const [data, setData] = useState<Note[]>([]);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<any>();
  const [snap, setSnap] = useState(0);

  useEffect(() => {
    async function load() {
      if (busy) return;

      setBusy(true);
      setError(null);
      try {
        const data = await service.api.patient.note.query(patientId);
        setData(data);
      } catch (e) {
        setError(e);
      }
      setBusy(false);
    }

    load();
  }, [patientId, snap]);

  const updateNote = async (item: Note, note?: string) => {
    const result = [...data];
    const target = result.find(e=>e.id == item.id);
    if(!target) return;

    target.note = note;
    setData(result);

    await service.api.patient.note.update(patientId, target);
  }

  const reload = () => setSnap(snap+1);

  const createNote = async () => {
    await service.api.patient.note.insert(patientId, {note: ''});    
    reload();
  }

  return <Body busy={busy} error={error}>
    <Menu>
      <Menu.Item link icon='sync'  onClick={() => setSnap(snap + 1)}/>
      <Menu.Item link icon='add'  onClick={() => setSnap(snap + 1)}>
        <Button as='div' labelPosition='right' onClick={() => createNote()}>
          <Icon name='add'></Icon>
          Add Note
        </Button>
      </Menu.Item>
    </Menu>
    
    <Table>
      <Table.Header>
        <Table.HeaderCell content='At' width={2}/>
        <Table.HeaderCell content='Note'/>
      </Table.Header>
      <Table.Body>
        {data.map((row: any) => <Table.Row>
          <Table.Cell content={formatDateTime( row.at) } />
          <Table.Cell>
            <div className="ui input" style={{width:'100%'}}>
              <input type='text' value={row.note ?? ''} onChange={(e) => updateNote(row, e.currentTarget.value)} />
            </div>        
          </Table.Cell>
        </Table.Row>)}
      </Table.Body>
    </Table>

  </Body>;
};