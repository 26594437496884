interface Menu {
  id: string;
  title?: string;
  link: string;
  icon?: string;
}

export const menus: Menu[] = [
  {
    id: 'PatientFinder',
    title: 'Patients',
    link: '/patients',
  },
  {
    id: 'Settings',
    link: '/settings',
    icon: 'cogs',
  },
];