import React, { useEffect, useState } from "react"
import { Checkbox, Divider, Form, Menu, Segment, Table } from "semantic-ui-react"
import { service } from "~/src/service";
import { Body } from "~/src/ux/page";

type Link = {
    adminUserId: string;
    adminEmail: string;
    patientUserId: string;
    isEnabled: boolean;
}

type User = {
    userId: string;
    email: string;
    hasRoleRoot?: boolean;
    hasRoleAdmin?: boolean;
}

export const PatientAdmin = ({ patientId }: { patientId: string }) => {

    const [data, setData] = useState([]);
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState<any>();
    const [snap, setSnap] = useState(0);
    const [user, setUser] = useState<User>();

    useEffect(() => {
        async function load() {
            setBusy(true);
            setError(null);
            try {
                setData(await service.api.patient.admin.query(patientId));
            } catch (e) {
                setError(e);
            }
            try {
                setUser(await service.api.user.find(patientId));
            } catch (e) {
                setError(e);
            }
            setBusy(false);
        }

        load();
    }, [snap]);

    const reload = () => setSnap(snap + 1);

    const toggleAdminLink = async (link: Link) => {
        if (link.isEnabled) {
            await service.api.patient.admin.delete(link.patientUserId, link.adminUserId);
        } else {
            await service.api.patient.admin.add(link.patientUserId, link.adminUserId);
        }
        reload();
    };

    const updateUser = async ({ hasRoleAdmin, hasRoleRoot }: { hasRoleAdmin?: boolean, hasRoleRoot?: boolean }) => {
        await service.api.user.update(patientId, { hasRoleAdmin, hasRoleRoot });
        reload();
    }

    return <Body busy={busy} error={error}>
        <Table selectable definition compact celled>
            <Table.Header>
                <Table.HeaderCell />
                <Table.HeaderCell content='Admin By' />
            </Table.Header>
            <Table.Body>
                {data.map((row: Link) => <Table.Row key={row.adminUserId} onClick={() => toggleAdminLink(row)}>
                    <Table.Cell collapsing icon={row.isEnabled ? 'check square' : 'square outline'} />
                    <Table.Cell content={row.adminEmail} />
                </Table.Row>)}
            </Table.Body>
        </Table>


        <Divider hidden section />
        {user && <Segment>
            <p>User</p>

            <Divider />

            <Form>
                <Form.Group widths='equal'>
                    <Form.Input label='Is Root'>
                        <Checkbox checked={user.hasRoleRoot ?? false} onClick={() => updateUser({ hasRoleRoot: !user.hasRoleRoot })} />
                    </Form.Input>
                    <Form.Input label='Is Admin'>
                        <Checkbox checked={user.hasRoleAdmin ?? false} onClick={() => updateUser({ hasRoleAdmin: !user.hasRoleAdmin })} />
                    </Form.Input>
                </Form.Group>
            </Form>
        </Segment>}
    </Body>
}