import { Form } from '~/src/types';

export const forms: Form[] = [
  { code: 'GAD7', description: '	Anxiety assessment', },
  { code: 'WHODAS20', description: 'Functional assessment', },
  { code: 'SPADI', description: 'Pain limited activities', },
  { code: 'FACIT', description: 'Fatigue Scale', },
  { code: 'EQ-5D-3L', description: 'Quality of Life', },
  { code: 'HADS', description: 'Hospital Anxiety and Depresion Scale', },
  { code: 'PAR-Q', description: 'Physical Activity Readiness', },
  { code: 'PAS', description: 'Physical Activity Screening', },
  { code: 'NS', description: 'Malnutrition Screening Tool', },
  { code: 'DL', description: 'Difficulty Level', },
];