import React, { useContext, Component, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useHistory, Route, Switch, HashRouter as Router } from 'react-router-dom';
import { UserProvider, UserContext } from './providers/user_provider';
import { menus } from './menu';

import { Settings } from '~/src/views/settings/settings';
import { Profile } from '~/src/views/profile';
import { Dashboard } from '~/src/views/dashboard';

import { PatientFinder } from '~/src/views/patient/patient_finder';
import { PatientViewer } from '~/src/views/patient/patient_viewer';

import { Input, Menu, Segment, Label } from 'semantic-ui-react';
import logo from '~/assets/logo.png';
import { NudgeTemplateEditor } from './views/settings/nudge/nudge_template_editor';


const Shell = () => {

  const history = useHistory();
  const { user } = useContext(UserContext);

  return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

    <Menu pointing inverted fixed='top' style={{ borderRadius: '0px' }}>
      <Menu.Item header link onClick={() => history.push('/')}>
        <img alt="logo" src={logo} style={{ margin: '0px 8px' }} />
      </Menu.Item>

      {menus.map((e) => <Menu.Item key={e.id} link name={e.title} icon={e.icon} onClick={() => history.push(e.link)}></Menu.Item>)}

      <Menu.Menu position='right'>
        <Menu.Item link onClick={() => history.push('/profile')}>
          {user?.email ?? ''}
        </Menu.Item>
      </Menu.Menu>
    </Menu>

    {/* menu compensation */}
    <div style={{ height: '70px' }}></div>

    <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <main style={{ height: '100%', flexGrow: 1 }}>
        <Switch>
          <Route path='/patients/:id' component={PatientViewer} />
          <Route path='/patients' component={PatientFinder} />
          <Route path='/nudge-template/:id' component={NudgeTemplateEditor} />
          <Route path='/profile' component={Profile} />
          <Route path='/settings' component={Settings} />
          <Route path='/' component={PatientFinder} />
        </Switch>
      </main>
    </div>
  </div>
}

const Application = () => <UserProvider><Router><Shell /></Router></UserProvider>;
const root = createRoot(document.getElementById('root')!);
root.render(<Application />);