import React from 'react';
import { Button, Icon, Menu, Tab } from 'semantic-ui-react';
import { Page } from '~/src/ux';
import { FormFinder } from './form/form_finder';
import { NudgeTemplateFinder } from './nudge/nudge_template_finder';

export const Settings = () => {
    return <Page>
        <Menu secondary>
            <Menu.Item fitted>
                <Button icon labelPosition='left'>
                    <Icon name='cogs' />
                    Settings
                </Button>
            </Menu.Item>
        </Menu>

        <Tab menu={{ pointing: true }} panes={[
            { menuItem: 'Nudge Template', render: () => <NudgeTemplateFinder /> },
            { menuItem: 'Forms', render: () => <FormFinder /> },
        ]} />
    </Page>
}