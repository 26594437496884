import React from "react";
import { Table } from "semantic-ui-react";
import { formatDate } from "~/src/monad";

type Daily = { date: Date, cardio?: string, mindfulness?: string, strength?: string, nutrition?: number };

const prettify = (data: any[]): Daily[] => {
    const map = new Map<Date, Daily>();
    for (const item of data) {
        const date = item.date as Date;
        var ix = map.get(date);
        if (ix == undefined) {
            ix = { date, mindfulness: 'circle outline', strength: 'circle outline', cardio: 'circle outline' } as Daily;
            map.set(date, ix);
        }
        if (item.questKey === 'NUTRITION' && item.stepKey === 'PROTEIN') {
            ix.nutrition = (ix.nutrition ?? 0) + (+item.amount);
        }
        if (item.questKey === 'STRENGTH') {
            ix.strength = 'circle';
        }
        if (item.questKey === 'CARDIO') {
            ix.cardio = 'circle';
        }
        if (item.questKey === 'MINDFULNESS') {
            ix.mindfulness = 'circle';
        }

    }
    return Array.from(map.values());
}


export const PatientReportDaily = ({ data }: { data: any[] }) => <div>
    <Table celled selectable sortable definition compact>
        <Table.Header fullWidth>
            <Table.HeaderCell />
            <Table.HeaderCell>Cardio</Table.HeaderCell>
            <Table.HeaderCell>Mindfulness</Table.HeaderCell>
            <Table.HeaderCell>Strength</Table.HeaderCell>
            <Table.HeaderCell>Nutrition</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
            {prettify(data).map((row: Daily, i) =>
                <Table.Row key={i}>
                    <Table.Cell collapsing>{formatDate(row.date)}</Table.Cell>
                    <Table.Cell icon={row.cardio} />
                    <Table.Cell icon={row.mindfulness} />
                    <Table.Cell icon={row.strength} />
                    <Table.Cell>{row.nutrition}</Table.Cell>
                </Table.Row>
            )}
        </Table.Body>
    </Table>
</div>
