import React from 'react';
import { Dimmer, Loader, Segment, Image, Message } from 'semantic-ui-react';
import shortParagraph from '~/assets/short-paragraph.png';

export const Page = ({ children }: { children?: any }) => {
  return <div style={{ padding: '8px' }}>
    {children}
  </div>
};


export const Body = ({ children, busy, error }: { children?: any, busy?: boolean, error?: any }) => {
  if (error) {
    return <Message negative>
      <Message.Header>Error</Message.Header>
      <p>{JSON.stringify(error)}</p>
    </Message>
  }
  if (busy) {
    <Segment>
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <Image src={shortParagraph} />
    </Segment>
  }
  return children;
};